import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client'; // Import your Sanity client
import './PSIAnimation.scss'; // Import specific SCSS for the project
import { useParams, Link } from 'react-router-dom';

const PSIAnmation = () => {
  const [work, setWork] = useState({ name: "", description: "", imageurl: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = `*[slug.current == "psi"]`;
  }, []);

  return (
    <div id='psi'>
        <motion.h2
            className="large-text"
            whileInView={{ y: [15, 0], opacity: [0, 1] }}
            transition={{ duration: 1 }}
        >
            PSI Animation
        </motion.h2>

        <div className="dual-content-block dual-content-block--black-text">
            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    What Is It?
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    When new employees join a company, the initial days can often be overwhelming. Getting acclimated to the processes, culture, and expectations can be challenging. As an ECL Intern, I aimed to simplify this process, making it smoother and more efficient for their newest team members.
                </motion.p>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    Understanding the intricacies of the PSI (Press Sinter Infiltrate) process is crucial for ECL employees. To achieve this, I wanted to go beyond traditional training methods and provide a visual representation that would engage and educate simultaneously. See the video I created below!
                </motion.p>
            </div>
        </div>

        <div className="video-container">
        <iframe
            width="100%"
            height="500"
            src="https://www.youtube.com/embed/tbToajkS2As"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
        </div>
    
        <Link to={'/#work'}>
            <div className='back-button'>
                <p className='p-text bold-text'>Back to Works</p>
            </div>
        </Link>
    </div>
  );
};

export default PSIAnmation;
