import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Import react-ga4
import { About, Contact, Header, Work, Experience, Footer, SingleWork } from './container';
import { Navbar } from './components';
import './App.scss';
import ScrollToSection from './ScrollToSection';
import { Helmet } from 'react-helmet';
import {RobotArm} from './pages';
import CentrifugalPump from './pages/CentrifugalPump/CentrifugalPump';
import PSIAnimation from './pages/PSIAnimation/PSIAnimation';
import Launcho from './pages/Launcho/Launcho';

const HomePage = () => (
  <>
    <Header id="home" />
    {/*<About id="about" />*/}
    <Work id="work" />
    <Experience id="experience" />
    <Contact id="contact" />
    <Footer id="footer" />
  </>
);

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-XFJFSFPP7C"); // Replace with your Google Analytics ID
    ReactGA.send("pageview"); // Track initial page view
  }, []);

  useEffect(() => {
    const location = window.location.pathname;
    ReactGA.send({ hitType: "pageview", page: location }); // Track pageview on route change
  }, [window.location.pathname]);

  return (
    <BrowserRouter>
      <div className='app'>
        <Helmet>
          <title>Anthony Qiu</title>
        </Helmet>
        <Navbar />
        <ScrollToSection />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/work/robotic-arm' element={<RobotArm />} />
          <Route path='/work/centrifugal-pump' element={<CentrifugalPump />} />
          <Route path='/work/psi' element={<PSIAnimation />} />
          <Route path='/work/launcho' element={<Launcho />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
