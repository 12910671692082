import React from 'react'
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0,1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = ({id}) => {
  return (
    <div id={id} className="app__header app__flex">
      <motion.div
  whileInView={{ x: [-15, 0], opacity: [0, 1] }}
  transition={{ duration: 2 }}
  className="app__header-info"
>
  <div className="app__header-badge">
    
    {/* First motion div */}
    <motion.div
      whileInView={{ opacity: [0, 1], x: [-10, 0] }}
      transition={{ duration: 1 }}
      className="badge-cmp app__flex"
    >
      <span>👋</span>
      <div style={{ marginLeft: 20 }}>
        <p className='p-text'>Hello, I am</p>
        <h1 className="head-text gradient-text">Anthony.</h1>
      </div>
    </motion.div>

    {/* Third motion div */}
    <motion.div
      whileInView={{ opacity: [0, 1], x: [-10, 0] }}
      transition={{ duration: 1, delay: 0.25 }}
      className='tag-cmp app__flex'
    >
      <p className='p-text'>I am currently pursuing a degree in mechanical engineering at the University of Waterloo, where I focus on advancing my technical knowledge and skills.</p>
    </motion.div>

    {/* Fourth motion div */}
    <motion.div
      whileInView={{ opacity: [0, 1], x: [-10, 0] }}
      transition={{ duration: 1, delay: 0.5 }}
      className='tag-cmp app__flex'
    >
      <p className='p-text'>In addition to my studies, I work on side projects that allow me to apply engineering principles and further develop my expertise.</p>
    </motion.div>

    {/* Fifth motion div */}
    <motion.div
      whileInView={{ opacity: [0, 1], x: [-10, 0] }}
      transition={{ duration: 1, delay: 0.75 }}
      className='tag-cmp app__flex'
    >
      <p className='p-text'>Outside of academics, I enjoy playing volleyball as part of maintaining a well-rounded lifestyle.</p>
    </motion.div>
  </div>
</motion.div>

      
      <motion.div
        whileInView={{ opacity: [0,1], y: [20, 0] }}
        transition={{ duration: 2, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt='profile_bg' />
        {/*}
        <motion.img
          whileInView={{ scale: [0,1] }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          src={images.ink}
          alt='profile_circle'
          className='overlay_circle'
        />*/}
      </motion.div>

      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.solidworks, images.threedp, images.lathe].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt='circle' />
          </div>
        ))}
      </motion.div>

    </div>
  )
}

//export default AppWrap(Header, 'home');
export default Header;