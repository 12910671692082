import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';

import './About.scss';
import { client } from '../../client'; // Removed urlFor as it's no longer needed

const About = ({id}) => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "about"]';

    client.fetch(query)
      .then((data) => {
        console.log("Fetched data:", data); // Debugging
        setAbouts(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error); // Debugging
      });
  }, []);

  console.log("Abouts:", abouts); // Debugging

  return (
    <div id={id} className="app__flex">
      <motion.div
        whileInView={{ y: [15, 0], opacity: [0,1] }}
        transition={{ duration: 1 }}
      >
        <h2 className='head-text gradient-text'>
          About Me
        </h2>
      </motion.div>
      <motion.div
        whileInView={{ y: [15, 0], opacity: [0,1] }}
        transition={{ duration: 1 }}
      >
        <p className='p-text-large'>
          I am currently pursuing a degree in mechanical engineering at the University of Waterloo, where I focus on advancing my technical knowledge and skills. In addition to my studies, I work on side projects that allow me to apply engineering principles and further develop my expertise. Outside of academics, I enjoy playing volleyball as part of maintaining a well-rounded lifestyle.
        </p>
      </motion.div>

      {/* Removed the profile items section */}
    </div>
  )
}

export default About;
