import React, { useState } from 'react';

import { motion } from 'framer-motion';
import { client } from '../../client';
import './Contact.scss';

const Contact = ({id}) => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
        setFormData({ username: '', email: '', message: '' }); // Clear form fields
      })
      .catch((err) => console.log(err));
  };

  return (
    <div id={id} className="app__header app__flex">
      <motion.div
        whileInView={{ y: [15, 0], opacity: [0,1] }}
        transition={{ duration: 1 }}
      >
        <h2 className="head-text">Contact Me</h2>
      </motion.div>

      
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
        </div>
      ) : (
        <div className='app__contact-thanks'>
          <h3 className="bold-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </div>
  );
};


export default Contact
