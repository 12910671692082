// import logo from '../assets/logo.png';
import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import flutter from '../assets/flutter.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import bgIMG from '../assets/bgIMG.png';
import solidworks from '../assets/solidworks.png';
import threedp from '../assets/threedp.png';
import lathe from '../assets/lathe.png';
import ink from '../assets/ink.png';
import logo from '../assets/logo.png';

const images = {
    // logo,
    profile,
    circle,
    flutter,
    redux,
    sass,
    bgIMG,
    solidworks,
    threedp,
    lathe,
    ink,
    logo,
};

export default images;