import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client'; // Import your Sanity client
import './CentrifugalPump.scss'; // Import specific SCSS for the project
import { useParams, Link } from 'react-router-dom';

const CentrifugalPump = () => {
  const [work, setWork] = useState({ name: "", description: "", imageurl: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = `*[slug.current == "centrifugal-pump"]`;
  }, []);

  return (
    <div id='centrifugal-pump'>
        <motion.h2
            className="large-text"
            whileInView={{ y: [15, 0], opacity: [0, 1] }}
            transition={{ duration: 1 }}
        >
            Centrifugal Pump
        </motion.h2>

        <div className="dual-content-block dual-content-block--black-text">
            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    What Is It?
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    This compressor was designed as part of a first year design project. Our project was to create a robot which prepares a soda drink. To do this, we needed some sort of gripper to pick up the lid. We decided that a suction gripper would be the simplest. As such, we designed a centrifugal compressor to generate suction force.
                </motion.p>
            </div>
            <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="dual-content-block__image-oversized"
            >
                <img src={require('./assets/pump-1.png')} alt="rob-2" />
            </motion.div>
        </div>

        <div className="dual-content-block dual-content-block--black-text">
            <motion.div
                whileInView={{ opacity: [0,1], y: [20, 0] }}
                transition={{ duration: 2, delayChildren: 0.5 }}
                className="dual-content-block__image-oversized"
            >
                <img src={require('./assets/pump-2.png')} alt="rob-2" />
            </motion.div>
            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    How It Came to Life
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    We started by researching centrifugal pump mechanics, focusing on how impeller shape and volute design influence performance. Using SolidWorks, we modeled multiple designs, refining the geometry to maximize suction power. To quickly test and improve our design, we used 3D printing to fabricate prototypes, allowing for rapid iteration and hands-on evaluation. By testing different impeller and volute shapes, we fine-tuned the system for maximum efficiency.
                </motion.p>
            </div>
        </div>

        <div className="dual-content-block dual-content-block--black-text">
            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    Final Result
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    The final compressor was fully 3D-printed and powered by a single LEGO EV3 motor with a carefully selected gear ratio. It successfully generated enough suction to lift a 4g plastic lid, proving the effectiveness of our design. This project reinforced our understanding of mechanism design, prototyping, and the value of rapid iteration, all while demonstrating how simple mechanical systems can achieve precise functionality.
                </motion.p>
            </div>
            <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="dual-content-block__image"
            >
                <img src={require('./assets/pump-3.jpg')} alt="rob-2" />
            </motion.div>
        </div>

        <div className="video-container">
            <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/7Qd4jeNrErw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>

    
        <Link to={'/#work'}>
            <div className='back-button'>
                <p className='p-text bold-text'>Back to Works</p>
            </div>
        </Link>
    </div>
  );
};

export default CentrifugalPump;
