import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client'; // Import your Sanity client
import './Launcho.scss'; // Import specific SCSS for the project
import { useParams, Link } from 'react-router-dom';

const Launcho = () => {
  const [work, setWork] = useState({ name: "", description: "", imageurl: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = `*[slug.current == "launcho"]`;
  }, []);

  return (
    <div id='launcho'>
        <motion.h2
            className="large-text"
            whileInView={{ y: [15, 0], opacity: [0, 1] }}
            transition={{ duration: 1 }}
        >
            Launcho
        </motion.h2>

        <div className="dual-content-block dual-content-block--black-text">
            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    What Is It?
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    Launcho is a spring-powered projectile launcher designed by a team of five, including myself, during my first year of engineering. It can fire on command while adjusting its launch angle across all three dimensions.
                </motion.p>
            </div>
            <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="dual-content-block__image-oversized"
            >
                <img src={require('./assets/launcho-1.png')} alt="rob-2" />
            </motion.div>
        </div>

        <div className="dual-content-block dual-content-block--black-text">
            <motion.div
                whileInView={{ opacity: [0,1], y: [20, 0] }}
                transition={{ duration: 2, delayChildren: 0.5 }}
                className="dual-content-block__image-oversized"
            >
                <img src={require('./assets/launcho-2.jpg')} alt="rob-2" />
            </motion.div>
            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    How It Came to Life
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    We started by reverse-engineering a Nerf mechanism to understand how spring-powered launching works. Our first prototype—a rough build made of PEX pipe, Nerf gun parts, and duct tape—helped us validate the concept. Once we had proof of feasibility, we modeled the final design in SolidWorks, consisting of nine precisely designed components. To achieve both precision and durability, we used 3D printing for some parts and machined others from steel. The final assembly was completed using force fits, super glue, and a spray-painted finish for aesthetics.
                </motion.p>
            </div>
        </div>
    
        <Link to={'/#work'}>
            <div className='back-button'>
                <p className='p-text bold-text'>Back to Works</p>
            </div>
        </Link>
    </div>
  );
};

export default Launcho;
