import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import './Navbar.scss';
import { images } from '../../constants';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const handleNavClick = () => {
    setToggle(false);
  };

  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        {/*<h2 className='head-text gradient-text'>AQ</h2>*/}
        <img src={images.logo} alt='profile_bg' />
      </div>
      <ul className='app__navbar-links'>
        {['home', 'work', 'experience', 'contact'].map((item) => (
          <li className='app__flex p-text' key={`link-${item}`}>
            <div />
            <NavLink to={`/#${item}`} onClick={handleNavClick}>
              {item}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className='app__navbar-menu'>
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <div>
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'work', 'experience', 'contact'].map((item) => (
                <li key={item}>
                  <NavLink to={`/#${item}`} onClick={handleNavClick}>
                    {item}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
