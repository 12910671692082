import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client'; // Import your Sanity client
import './RobotArm.scss'; // Import specific SCSS for the project
import { useParams, Link } from 'react-router-dom';

const RobotArm = () => {
  const [work, setWork] = useState({ name: "", description: "", imageurl: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = `*[slug.current == "robot-arm"]`; // Get the "robot-arm" data
  }, []);

  return (
    <div id='robot-arm'>
        <motion.h2
            className="massive-text gradient-text-2"
            whileInView={{ y: [15, 0], opacity: [0, 1] }}
            transition={{ duration: 1 }}
        >
            ROB
        </motion.h2>

        <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="image-oversized"
        >
            <img src={require('./assets/vertical.png')} alt='rob-vertical' />
        </motion.div>

        <div className="dual-content-block">
            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    ROB
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    is a project which I started in the first year of my undergrad. It is a robotic arm with 4 degrees of freedom. All components including mechanical, electrical, and software are all designed, tested, and assembled by me.
                </motion.p>
            </div>
            <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="dual-content-block__image-oversized"
            >
                <img src={require('./assets/rob-2.png')} alt="rob-2" />
            </motion.div>
        </div>

        <div className="dual-content-block">
            <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="dual-content-block__image"
            >
                <img src={require('./assets/rob-3.png')} alt="rob-2" />
            </motion.div>

            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    Joint 1
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    The first joint powers the arm with a 4X torque boost using a planetary gearbox, keeping it compact yet strong. A custom 3D-printed cross-roller bearing ensures rigidity and smooth rotation, while a limit switch provides precise positioning. Driven by a NEMA 17 stepper motor, this joint lays the foundation for the arm’s strength and accuracy. Keep scrolling for an exploded view of Joint 1.
                </motion.p>
            </div>
        </div>

        <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="image-oversized"
        >
            <img src={require('./assets/rob-4.png')} alt='rob-vertical' />
        </motion.div>

        <div className="dual-content-block">
            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    Joint 2
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    Joint 2 packs a 14X torque increase using a fully 3D-printed cycloidal drive, delivering high power in a compact form. A belt drive with a built-in tensioner ensures smooth power transmission, while a limit switch provides precise positioning for repeatable movements.
                </motion.p>
            </div>
            <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="dual-content-block__image"
            >
                <img src={require('./assets/rob-5.png')} alt="rob-2" />
            </motion.div>
        </div>

        <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="image-regular"
        >
            <img src={require('./assets/rob-6.png')} alt='rob-big-image' />
        </motion.div>

        <div className="dual-content-block">
            <motion.div
            whileInView={{ opacity: [0,1], y: [20, 0] }}
            transition={{ duration: 2, delayChildren: 0.5 }}
            className="dual-content-block__image-oversized"
            >
                <img src={require('./assets/rob-7.png')} alt="rob-2" />
            </motion.div>

            <div className="dual-content-block__text">
                <motion.h2
                        className='head-text'
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    Joint 3/4
                </motion.h2>
                <motion.p
                        whileInView={{ y: [15, 0], opacity: [0,1] }}
                        transition={{ duration: 1 }}
                >
                    Joints 3/4 use a bevel gear differential adding 2 degrees of freedom to the design. A belt drive system with adjustable tensioners ensures efficient motion, while a lightweight 3D-printed housing reduces rotational inertia for smoother operation.
                </motion.p>
            </div>
        </div>
    
        <Link to={'/#work'}>
            <div className='back-button'>
                <p className='p-text bold-text'>Back to Works</p>
            </div>
        </Link>
    </div>
  );
};

export default RobotArm;
